// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-building-js": () => import("./../../../src/pages/building.js" /* webpackChunkName: "component---src-pages-building-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-company-sum-js": () => import("./../../../src/pages/company_sum.js" /* webpackChunkName: "component---src-pages-company-sum-js" */),
  "component---src-pages-concept-js": () => import("./../../../src/pages/concept.js" /* webpackChunkName: "component---src-pages-concept-js" */),
  "component---src-pages-concept-old-js": () => import("./../../../src/pages/concept_old.js" /* webpackChunkName: "component---src-pages-concept-old-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-contacts-result-js": () => import("./../../../src/pages/contacts-result.js" /* webpackChunkName: "component---src-pages-contacts-result-js" */),
  "component---src-pages-doshi-js": () => import("./../../../src/pages/doshi.js" /* webpackChunkName: "component---src-pages-doshi-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-old-js": () => import("./../../../src/pages/index_old.js" /* webpackChunkName: "component---src-pages-index-old-js" */),
  "component---src-pages-recruiting-js": () => import("./../../../src/pages/recruiting.js" /* webpackChunkName: "component---src-pages-recruiting-js" */),
  "component---src-pages-useageflow-js": () => import("./../../../src/pages/useageflow.js" /* webpackChunkName: "component---src-pages-useageflow-js" */)
}

